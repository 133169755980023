<script setup lang="ts">
import type {
  ErrorResponse,
  ValidationErrorResponse,
} from "~/types/apiResponses"

import sanitizeHtml from "sanitize-html"

import { getErrorMessage } from "~/utils/errorHandling"

const props = defineProps<{
  error: string | ErrorResponse | ValidationErrorResponse
}>()

const message = computed(() => {
  let message = getErrorMessage(props.error)
  if (message == null) return undefined

  message = message.replaceAll("\n", "<br>")

  return sanitizeHtml(message, {
    allowedTags: ["br"],
  })
})
</script>

<template>
  <div
    class="flex max-w-fit items-center gap-4 rounded-md border-2 border-red-600 bg-red-600 bg-opacity-[15%] px-4 py-4 font-bold text-red-600">
    <Icon name="mingcute:information-fill" class="text-[24px] flex-shrink-0" />

    <!-- We can disable this warning because we are sanitizing the message -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-html="message"></p>
  </div>
</template>
